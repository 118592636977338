/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SelectLinkDetails } from "../models/SelectLinkDetails";
import type { SelectLinkOrder } from "../models/SelectLinkOrder";
import type { SelectLinkOrderResponse } from "../models/SelectLinkOrderResponse";
import type { SelectLinkProducts } from "../models/SelectLinkProducts";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class WalletService {
  /**
   * Get link details
   * @param tokenCode
   * @param exp
   * @returns SelectLinkDetails OK
   * @throws ApiError
   */
  public static selectLinkDetails(
    tokenCode: string,
    exp?: string
  ): CancelablePromise<SelectLinkDetails> {
    return __request({
      method: "POST",
      path: `/v1/select-link-detail/${tokenCode}`,
      query: {
        exp: exp,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Order products for link
   * @param tokenCode
   * @param requestBody
   * @returns SelectLinkOrderResponse OK
   * @throws ApiError
   */
  public static selectLinkOrder(
    tokenCode: string,
    requestBody: SelectLinkOrder
  ): CancelablePromise<SelectLinkOrderResponse> {
    return __request({
      method: "POST",
      path: `/v1/select-link-detail/${tokenCode}/order`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get list of Products available for link
   * @param tokenCode
   * @returns SelectLinkProducts OK
   * @throws ApiError
   */
  public static selectLinkProductList(
    tokenCode: string
  ): CancelablePromise<SelectLinkProducts> {
    return __request({
      method: "GET",
      path: `/v1/select-link-detail/${tokenCode}/products`,
      errors: {
        403: `Forbidden`,
      },
    });
  }
}
