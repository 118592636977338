<script setup lang="ts">
import { onMounted, ref } from "vue";
import { SelectLinkProduct } from "../../api.generated/scion";
import { useProductStore } from "../../stores/product";
import { Product, Category } from "../index";
import Fuse from "fuse.js";

let searchInProgress = ref<boolean>(false);
let filteredProducts = ref<Array<SelectLinkProduct>>([]);
let previousFilteredProducts = ref<Array<SelectLinkProduct>>([]);
let filteredCategories = ref<Array<string>>([]);
let allCategoryNames = ref<Array<string>>([]); // list of all categories available
let showAllCategories = ref<boolean>(false);
let isSearchResultsVisible = ref<boolean>(false);
let searchValue = ref<string>("");
let matchingCategoriesListIsEmpty = ref<boolean>(true);
let hideCategoriesShowBackButton = ref<boolean>(false);
let resultsHeading = ref<string>("Results");

const productStore = useProductStore();
const allProducts = productStore.products;
const allCategories = productStore.availableCategories;
const minNoOfCharacters = 3;
const defaultResultsHeading = "Results";
const productSearchLibrary = new Fuse(allProducts, {
  threshold: 0.3,
  minMatchCharLength: minNoOfCharacters,
  distance: 10,
  keys: ["name", "keywords"],
});
const categorySearchLibrary = new Fuse(allCategories, {
  threshold: 0.3,
  minMatchCharLength: minNoOfCharacters,
  keys: ["name"],
});
const productCategorySearchLibrary = new Fuse(allProducts, {
  threshold: 0.3,
  minMatchCharLength: minNoOfCharacters,
  findAllMatches: true,
  keys: ["categories"],
});

const emit = defineEmits(["searchEvent", "assetSelected", "cancelSearchEvent"]);

onMounted(async () => {
  allCategories.forEach((category) => {
    allCategoryNames.value.push(category.name);
  });
});

function startSearch() {
  // - start a new search
  searchInProgress.value = true;
  isSearchResultsVisible.value = true;
}

function resetSearch() {
  // - hide the search results
  // - clear the searchValue
  searchInProgress.value = false;
  isSearchResultsVisible.value = false;
  searchValue.value = "";
}

function noSearchIsInProgress(): boolean {
  return !searchInProgress.value;
}

function searchIsInProgress(): boolean {
  return searchInProgress.value;
}

function cancelSearch() {
  // when the search is cancelled using the "Cancel" button
  // - reset the product list to be all products
  // - clear the filtered categories list
  // - reset the search
  // - emit searchEvent to let the home page know the search was updated
  resetSearch();
  filteredProducts.value = productStore.products;
  filteredCategories.value = [];
  emit("cancelSearchEvent");
}

function filterByCategory(category: string) {
  // when the user selects a category from the search results
  // - convert the category name to the category code
  // - search the categories in the product list for the code

  previousFilteredProducts.value = filteredProducts.value;
  filteredProducts.value = [];

  let code = getCategoryCode(category);
  let productList = productCategorySearchLibrary.search(code!);
  filteredProducts.value = productList.map(({ item }) => item);
  hideCategoriesShowBackButton.value = true;
  resultsHeading.value = category;
}

function noProductsOrCategoriesMatch(): boolean {
  // determine whether no products nor categories match the search term
  let searchBoxIsEmpty = searchValue.value.trim().length == 0;
  let matchingProductsListIsEmpty = filteredProducts.value.length == 0;
  matchingCategoriesListIsEmpty.value = filteredCategories.value.length == 0;

  return (
    matchingCategoriesListIsEmpty.value &&
    (matchingProductsListIsEmpty || searchBoxIsEmpty)
  );
}

function displayAllCategories() {
  // display a full list of all categories
  showAllCategories.value = true;
  filteredCategories.value = allCategoryNames.value;
}

function onSearchCharacterAdded(event: any) {
  // Every time a key is pressed in the search box, the search is updated
  // - record the fact that an entry has been made in the search bar
  // - emit a searchEvent to let the home page know that a search has been started
  // - filter products and categories according to the search term

  hideCategoriesShowBackButton.value = false;
  if (noSearchIsInProgress()) {
    startSearch();
    emit("searchEvent", true);
  }

  filteredProducts.value = [];
  filteredCategories.value = [];
  showAllCategories.value = false;
  resultsHeading.value = defaultResultsHeading;

  searchValue.value = event.target.value;
  let productList = productSearchLibrary.search(searchValue.value);
  filteredProducts.value = productList.map(({ item }) => item);

  let categoryList = categorySearchLibrary.search(searchValue.value);
  filteredCategories.value = categoryList.map(({ item }) => item.name);

  if (noProductsOrCategoriesMatch()) {
    displayAllCategories();
  }
}

function onClickProduct(product: SelectLinkProduct) {
  emit("assetSelected", product);
}

function getCategoryName(code: string): string | undefined {
  const category = productStore.categories.find((c) => c.code === code);
  return category?.name;
}

function getCategoryCode(name: string): string | undefined {
  const category = productStore.categories.find((c) => c.name === name);
  return category?.code;
}

function backToCategorySearchResults() {
  resultsHeading.value = defaultResultsHeading;
  filteredProducts.value = previousFilteredProducts.value;
  hideCategoriesShowBackButton.value = false;
}
</script>

<template>
  <div>
    <div>
      <div class="relative">
        <div
          class="pointer-events-none absolute inset-y-0 left-0 mt-4 items-center pl-3"
        >
          <svg
            aria-hidden="true"
            class="h-5 w-5 text-gray-500 dark:text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
        </div>
        <div class="flex">
          <span class="flex-1">
            <input
              @input="onSearchCharacterAdded($event)"
              :value="searchValue"
              type="search"
              id="default-search"
              class="w-full appearance-none rounded-lg border-2 border-white bg-gray-100 p-4 pl-10 text-sm text-gray-800 outline-none focus:border-black"
              placeholder="Search"
              aria-label="Search for products or categories"
              required
            />
          </span>
          <span class="flex-none">
            <button
              @click="cancelSearch"
              v-if="searchIsInProgress()"
              class="text-grey-550 bg-transparent p-3 font-light hover:text-grey-200"
            >
              Cancel
            </button>
          </span>
        </div>
      </div>
    </div>
    <div v-if="searchIsInProgress()">
      <div
        v-if="
          filteredProducts.length == 0 &&
          showAllCategories &&
          searchValue.length > minNoOfCharacters
        "
        class="py-4 text-sm"
      >
        We couldn't find any results for "{{ searchValue }}"
      </div>
      <div class="text-sm" v-if="!hideCategoriesShowBackButton">
        <h2
          v-if="!matchingCategoriesListIsEmpty || showAllCategories"
          class="py-4 font-semibold"
        >
          Categories
        </h2>
        <div v-for="category in filteredCategories">
          <Category
            :key="category"
            :category="category"
            class="mr-2 shrink-0 basis-4/12"
            @categoryFilterSelected="filterByCategory"
          >
          </Category>
        </div>
      </div>
      <div class="text-sm" v-if="hideCategoriesShowBackButton">
        <div class="border-b border-grey-300 pb-2 pt-8">
          <div class="flex" @click="backToCategorySearchResults">
            <span class="flex-none pr-4">
              <button type="button" class="text-grey-700">
                <font-awesome-icon :icon="['far', 'chevron-left']" />
              </button>
            </span>
            <span class="flex-2"> Back To Results </span>
          </div>
        </div>
      </div>
      <h2
        v-if="filteredProducts.length !== 0 && isSearchResultsVisible"
        class="py-4 font-semibold"
      >
        {{ resultsHeading }}
      </h2>
      <div
        v-if="filteredProducts.length != 0 && isSearchResultsVisible"
        v-for="product in filteredProducts"
        class="text-grey-550 flex cursor-pointer py-2 text-sm"
        @click="onClickProduct(product)"
      >
        <span class="w-[105px] shrink-0 md:w-[142px] lg:w-[112px]">
          <Product
            :key="product.code"
            :product="product"
            class="mr-2 shrink-0 basis-4/12"
          >
          </Product>
        </span>
        <span class="ml-4 flex-70">
          <div class="mb-1 font-semibold">{{ product.name }}</div>
          <div
            class="flex text-sm text-gray-500"
            v-for="category in product.categories"
            :key="category"
          >
            {{ getCategoryName(category) }}
          </div>
        </span>
      </div>
    </div>
  </div>
</template>
