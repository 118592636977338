<script setup lang="ts">
import { useLinkDetailsStore } from "../../stores/linkDetails";
import {
  getRemainingDuration,
  getCurrentCredit,
} from "../../utils/stores/utils";
import { formatDateWithUserLocale } from "../../utils/format";
import { computed } from "vue";

const linkDetailsStore = useLinkDetailsStore();

const expiryDateString = linkDetailsStore.expiryDate as string;

// Simple computed properties for aria labels
const balanceAmount = computed(() => getCurrentCredit(linkDetailsStore));
const expiryInfo = computed(() => {
  return !linkDetailsStore.isLinkExpired
    ? `Expires in ${getRemainingDuration(expiryDateString)}`
    : `Your balance expired on ${formatDateWithUserLocale(expiryDateString)}`;
});
</script>

<template>
  <div>
    <div
      v-if="!linkDetailsStore.logoUrl.endsWith('placeholder.png')"
      class="mb-6"
    >
      <img :src="linkDetailsStore.logoUrl" alt="Product logo" />
    </div>
    <div class="font-f37lineca">Balance</div>
    <div
      class="font-f37lineca text-[42px] leading-[48px] tracking-tight"
      tabindex="0"
      :aria-label="`Balance amount: ${balanceAmount}`"
    >
      {{ balanceAmount }}
    </div>
    <div class="text-xs font-extralight" tabindex="0" :aria-label="expiryInfo">
      <span>{{ expiryInfo }}</span>
    </div>
  </div>
</template>

<style scoped>
.text-balance {
  font-size: 42px;
}
</style>
