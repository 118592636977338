/**
 * Formats a list of strings into a grammatically correct English, with commas and "and" at the end.
 *
 * @param list List of strings to be concatenated
 */
import { CurrencyEnum } from "../api.generated/scion";

export function formatList(list: string[]): string {
  return [list.slice(0, -1).join(", "), list[list.length - 1]]
    .filter((el) => !!el)
    .join(" and ");
}

export function formatCurrencyValue(currency: string, value: number) {
  return value.toLocaleString("en-US", {
    style: "currency",
    currency: currency,
  });
}

export function formatDateWithUserLocale(dateString: string) {
  const date = new Date(dateString);
  const userLocale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;

  return date.toLocaleDateString(userLocale, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

export function formatHyperLink(text?: string) {
  if (!text) return "";
  const reWithBrackets = /\[(.*?)\]\((.*?)\)/g;
  const re = /(^|[^"'])(https?:\/\/[^\s]+)/g;
  const replaceText = text.replace(reWithBrackets, '<a href="$2">$1</a>');
  return replaceText.replace(re, '$1<a href="$2">$2</a>');
}

// Minimal handler for wrong use of "_" instead of "-" in IETF BCP 47 language tags
export function localeToIetfStandard(locale: string): string {
  return locale.replaceAll("_", "-");
}
