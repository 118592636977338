<script setup lang="ts">
import LoadingSpinner from "@two-components/components/LoadingSpinner.vue";

const props = defineProps<{
  translationsLoaded: boolean;
}>();
</script>

<template>
  <LoadingSpinner />
  <p v-if="translationsLoaded" class="font-heading mt-4 text-xl font-extrabold">
    {{ $t("message.loading_balance") }}
  </p>
</template>
