<script setup lang="ts">
import { Modal, CloseButton } from "../index";

const emit = defineEmits(["closeErrorModal"]);

function onCloseClick() {
  emit("closeErrorModal");
}
</script>
<template>
  <Modal
    size="small"
    title="We couldn't retrieve your gift card"
    :closable="true"
    @onCloseClick="onCloseClick"
  >
    <template #body>
      <p>Please try again shortly. Your balance hasn't changed.</p>
    </template>
    <template #button>
      <button
        class="w-full rounded-xl border-2 border-black px-6 py-3 font-semibold text-black transition duration-100 ease-in-out hover:bg-gray-100 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        @click="onCloseClick"
      >
        Ok
      </button>
    </template>
  </Modal>
</template>
