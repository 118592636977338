<script setup lang="ts">
import {
  Advert,
  BalanceDetails,
  CustomerLogo,
  YourAssets,
  ProductList,
  AssetAmountModal,
  Search,
  Basket,
  BasketFAB,
  ExpiredBanner,
  BasketModal,
  PopularAssets,
  StickyHeader,
} from "../../components";
import axios from "axios";
import { useLinkDetailsStore } from "../../stores/linkDetails";
import { useProductStore } from "../../stores/product";
import { useTokenStore } from "../../stores/token";
import { useNavigationStore } from "../../stores/navigation";
import { PurchasedGift, SelectLinkProduct } from "../../api.generated/scion";

import { computed, ref, onMounted, onBeforeMount } from "vue";
import { RouteNames } from "../../utils/routes";
import { getAssetToken } from "../../utils/purchasedAsset";
import { useRoute, useRouter } from "vue-router";
import { isExpired } from "../../utils/stores/utils";
import { generatePopularAssets } from "../../utils/popularAssets";
import { useBasketStore, ProductSource } from "../../stores";
import { event as gaEvent } from "vue-gtag";

const route = useRoute();
const router = useRouter();
const productStore = useProductStore();
const linkDetailsStore = useLinkDetailsStore();
const navigationStore = useNavigationStore();
const basketStore = useBasketStore();

const token = useTokenStore().token;
const allProducts = productStore.products;

const selectedProduct = ref<SelectLinkProduct | null>();
const productSource = ref<ProductSource>(ProductSource.Regular);
const isSearchOpen = ref<boolean>(false);
const filteredProducts = ref<Array<SelectLinkProduct>>([]);
const isLinkExpired = ref<boolean>(linkDetailsStore.isLinkExpired);
const showBasket = ref<boolean>(false);
const popularProducts = ref<SelectLinkProduct[]>([]);
const productsThatRequireDisclaimer = ref<string[]>([]);

function selectProduct(product: SelectLinkProduct, source: ProductSource) {
  productSource.value = source;
  selectedProduct.value = product;
}

function toggleProductList(visible: boolean) {
  isSearchOpen.value = visible;
}

const activePurchasedAssets = computed(() => {
  return linkDetailsStore.purchasedAssets.filter(
    (asset) => !isExpired(asset.expiry)
  );
});

function searchCancelled() {
  // the search was cancelled
  // - re-populate the product list with all products
  // - show the product list
  filteredProducts.value = allProducts;
  isSearchOpen.value = false;
}

async function completePurchase(numberOfAssets: number) {
  try {
    await linkDetailsStore.loadLinkDetails(token);
  } catch (e) {
    console.error(e);
    router.push({ name: RouteNames.Error });
  }

  selectedProduct.value = null;
  showBasket.value = false;

  navigationStore.setNumberOfAssets(numberOfAssets);
  navigationStore.setPurchaseSuccess(true);

  router.push({
    name: RouteNames.Assets,
    query: route.query,
  });
}

function addToBasket() {
  selectedProduct.value = null;
  showBasket.value = true;
}

function closeBasketModal() {
  showBasket.value = false;
}

function cancelPurchase() {
  selectedProduct.value = null;
}

function onAssetSelected(asset: PurchasedGift) {
  const assetToken = getAssetToken(asset.redeemUrl as string);
  const exp = route.query.exp;

  router.push({
    name: RouteNames.Spend,
    params: {
      assetToken,
    },
    query: {
      exp: exp, // pass the exp query param so that customisations are correctly loaded in the spend page
    },
  });
}

function toggleBasket() {
  showBasket.value = !showBasket.value;
}

onBeforeMount(async () => {
  filteredProducts.value = allProducts;

  basketStore.setPurchasingErrorMessage("");

  productsThatRequireDisclaimer.value = (
    (import.meta.env["VITE_DISPLAY_DISCLAIMER_FOR"] || "") as string
  )
    .split(",")
    .map((code) => code.trim())
    .filter((code) => !!code && allProducts.find((p) => p.code === code));

  const popularCodes = import.meta.env[
    `VITE_FEATURED_PRODUCT_CODES_${linkDetailsStore.currency}`
  ] as string;
  if (popularCodes) {
    popularProducts.value = generatePopularAssets(
      allProducts,
      popularCodes.split(",").map((code) => code.trim())
    );
  }
});

onMounted(() => {
  gaEvent("page_view", {
    page_title: "Home",
    hostname: window.location.hostname,
  });
});
</script>

<template>
  <div
    class="customisations min-h-full"
    :style="{ '--bg-color': linkDetailsStore.customisation.backgroundColour }"
  >
    <transition name="header">
      <sticky-header></sticky-header>
    </transition>

    <div class="relative">
      <div class="m-auto flex max-w-7xl justify-center p-6">
        <div class="max-w-full flex-1 md:mr-6 md:max-w-2xl lg:mr-10">
          <BasketModal
            v-if="showBasket"
            @basketModalCloseClicked="closeBasketModal"
            @purchaseComplete="completePurchase"
            class="md:hidden"
          />
          <AssetAmountModal
            v-if="!!selectedProduct"
            :product="selectedProduct"
            :productSource="productSource"
            @assetAmountModalCloseClicked="cancelPurchase"
            @addToBasket="addToBasket"
            @cancelPurchase="cancelPurchase"
          />
          <div
            :class="[
              'mb-4 rounded-2xl bg-white md:mb-6 md:p-6',
              isSearchOpen && 'hidden md:block',
            ]"
          >
            <CustomerLogo
              v-if="!!linkDetailsStore.customisation.logoUrl"
            ></CustomerLogo>
            <BalanceDetails></BalanceDetails>
            <YourAssets
              v-if="activePurchasedAssets.length"
              :purchasedAssets="activePurchasedAssets"
              class="mt-6"
              @assetSelected="onAssetSelected"
            >
            </YourAssets>
          </div>
          <div class="mb-4 rounded-2xl bg-white md:mb-6 md:p-6">
            <Search
              v-if="!isLinkExpired"
              class="flex-1"
              @searchEvent="toggleProductList"
              @assetSelected="selectProduct"
              @cancelSearchEvent="searchCancelled"
            >
            </Search>

            <div v-if="linkDetailsStore.hasMobileAdvert" class="mt-4 md:hidden">
              <advert
                :image="linkDetailsStore.advert.mobileImageUrl"
                :target="linkDetailsStore.advert.mobileLink"
              />
            </div>

            <PopularAssets
              v-if="
                popularProducts.length > 0 &&
                !linkDetailsStore.isLinkExpired &&
                !isSearchOpen
              "
              class="mb-4 mt-4 md:mb-6"
              :popularAssets="popularProducts"
              @assetSelected="
                (product) => selectProduct(product, ProductSource.Popular)
              "
            />

            <ExpiredBanner v-if="isLinkExpired"></ExpiredBanner>

            <ProductList
              v-if="!isSearchOpen"
              :class="[
                'mb-4 md:mb-6',
                isLinkExpired && 'pointer-events-none opacity-50 blur-lg',
                isLinkExpired && 'max-h-64 overflow-y-hidden',
              ]"
              @assetSelected="
                (product) => selectProduct(product, ProductSource.Regular)
              "
              :products="filteredProducts"
            ></ProductList>
          </div>
        </div>
        <div
          v-if="!isLinkExpired"
          class="hidden md:visible md:block md:basis-4/12"
        >
          <div class="font-f37lineca pt-1 text-xl">Basket</div>
          <Basket @purchaseComplete="completePurchase" />
        </div>
      </div>
      <div v-if="!isLinkExpired" class="fixed inset-x-0 bottom-8 md:hidden">
        <BasketFAB
          class="m-auto w-fit"
          @onBasketFABClick="toggleBasket"
        ></BasketFAB>
      </div>
    </div>

    <div class="m-auto max-w-[73rem] px-6">
      <footer
        class="mb-28 flex flex-col items-center justify-center border-t border-zinc-200 py-6 md:mb-0 md:flex-col md:items-start"
      >
        <div
          v-if="productsThatRequireDisclaimer.length"
          class="mb-6 flex-1 text-sm md:mb-0 md:mr-6"
        >
          <p
            v-for="productCode in productsThatRequireDisclaimer"
            class="disclaimer mb-4 text-center last:mb-0 md:text-left"
            v-html="
              allProducts.find((product) => product.code === productCode)
                ?.disclaimer
            "
            :key="productCode"
          ></p>
        </div>
        <a
          class="flex shrink-0 items-center"
          href="https://runa.io"
          target="_blank"
          aria-label="Powered by Runa link, opens runa.io in a new window"
        >
          Powered by
          <img
            class="ml-1"
            src="../../../public/static/img/runa-logo.svg"
            alt="Runa logo"
          />
        </a>
      </footer>
    </div>
  </div>
</template>

<style>
.disclaimer a {
  text-decoration: underline;
}
.header-enter-active {
  animation: slide-in 0.2s;
}
.header-leave-active {
  animation: slide-in 0.2s reverse;
}
@keyframes slide-in {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0%);
  }
}

.customisations {
  background-color: var(--bg-color);
}
</style>
